import React, { useState } from "react";
import axios from 'axios';
import "./contact.css";
import Loader from "react-js-loader";
import swal from 'sweetalert2'

const Contact = () => {
  const [contact, setContact] = useState({
    username: "",
    email: "",
    message: "",
  });

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setContact({
      ...contact,
      [name]: value,
    });
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const data = {
      Name: contact.username,
      Email: contact.email,
      Message: contact.message,
    };

    setIsLoading(true);
  
    try {
      const response = await axios.post('https://sheet.best/api/sheets/c932a293-4c21-4ea7-8219-82d6f8679bb0', data);
      setContact({ username: '', email: '', message: '' }); 
      setIsLoading(false);
      await swal.fire({
        title: "Submitted the form",
        text: "We will get back to you soon",
        icon: "success"
      });
    } catch (error) {
      console.error('Error submitting form data:', error);
      setIsLoading(false);
      await swal.fire({
        title: "Error",
        text: "Can you try again once with good internet",
        icon: "error"
      });
    }
  };
  


  return (
    <>
      {isLoading && (
        <div className="overlay">
          {/* Adjust the Loader props as needed */}
          <Loader bgColor={"#FFFFFF"} title={"loading"} color={'#FFFFFF'} size={100} />
        </div>
      )}
    <section className="section-contact">
    <p className="text-6xl sm:text-7xl md:text-8xl lg:text-9xl font-maintitle text-black text-center">Contact Us</p>
      <div className="container grid grid-two-cols">
        <div className="contact-img">
          <section className="mb-1">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.388094195557!2d80.23287127476239!3d13.010939987308069!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52679f0d20f797%3A0x59f9f10c66e02a19!2sCollege%20of%20Engineering%2C%20Guindy!5e0!3m2!1sen!2sin!4v1705930070224!5m2!1sen!2sin"
              width="1000px"
              height="450"
              allowFullScreen
              loading="lazy"
              title="Map"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </section>
        </div>
        <section className="section-form">
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="username" className="font-heading text-5xl">
                Name
              </label>
              <input
                type="text"
                name="username"
                id="username"
                autoComplete="off"
                value={contact.username}
                placeholder="Enter name"
                onChange={handleInput}
                required
                className="font-montserrat rounded"
              />
            </div>

            <div>
              <label htmlFor="email" className="font-heading text-5xl">
                email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                autoComplete="off"
                value={contact.email}
                placeholder="Enter Email"
                onChange={handleInput}
                required
                className="font-montserrat rounded"
              />
            </div>

            <div>
              <label htmlFor="message" className="font-heading text-5xl">
                message
              </label>
              <textarea
                name="message"
                id="message"
                autoComplete="off"
                value={contact.message}
                placeholder="Type here..."
                onChange={handleInput}
                required
                cols="30"
                rows="6"
                className="font-montserrat rounded"
              ></textarea>
            </div>

            <div>
              <button
                type="submit"
                className="rounded px-5 py-2.5 overflow-hidden group bg-green-500 relative hover:bg-gradient-to-r hover:from-green-500 hover:to-green-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-green-400 transition-all ease-out duration-300"
              >
                <span className="relative">Submit</span>
              </button>
            </div>
          </form>
        </section>
      </div>
    </section>
    </>
  );
};

export default Contact;
