import React from 'react';
import './App.css';



const EventCard = ({ event }) => {  
  return (
    <div className="max-w-lg mx-auto overflow-hidden bg-white dark:bg-gray-800 leading-relaxed transition-all duration-300 sm:max-w-5xl md:max-w-5xl lg:max-w-5xl">
      <img className="w-full h-200 object-cover" src={require(`./${event?.image}`)} alt={event?.name} />
      <div className="p-5 text-center">
        <p className="text-3xl sm:text-4xl md:text-4xl lg:text-5xl font-heading font-semibold text-blue-800">
          {event.name}
        </p>
        {event.teamsize && <span className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl font-heading text-gray-700">Team Size: {event.teamsize}</span>}
        <p className="mt-4 text-2xl sm:text-2xl md:text-2xl lg:text-2xl font-montserrat text-gray-600 text-justify tracking-tight">
          {event.description}
        </p>
        
        {event.rules && <div className="rules mt-4">
          <h3 className="text-3xl sm:text-4xl md:text-4xl lg:text-5xl font-heading font-semibold text-left">Rules:</h3>
          <ul>
            {event.rules.map((rule, index) => (
              <li className="mt-4 text-2xl sm:text-2xl md:text-2xl lg:text-2xl font-montserrat text-gray-600 text-justify tracking-tight" key={index}>{rule}</li>
            ))}
          </ul>
        </div>}
      </div>
      <p className="text-2xl sm:text-3xl md:text-3xl lg:text-3xl font-heading text-gray-700 px-5">Time and Date: {event.time_date}</p>
      <p className="text-2xl sm:text-3xl md:text-3xl lg:text-3xl font-heading text-gray-700 px-5">Venue: {event.venue}</p>
      <div className="pt-4 px-5 rounded-lg shadow">
        <h3 className="text-2xl sm:text-3xl md:text-3xl lg:text-3xl font-semibold text-gray-900 mb-2">Contact Information:</h3>
        <div className="flex items-center mb-3">
          <span role="img" aria-label="phone" className="text-base sm:text-lg md:text-xl lg:text-2xl mr-2">☎️</span>
          <div>
            <a href="tel:+919444257125" className="text-md text-blue-600 hover:text-blue-800 transition duration-300 ease-in-out">
              <p className="font-semibold text-base sm:text-lg md:text-xl lg:text-2xl">+91 9444257125</p>
              <p className="text-base sm:text-lg md:text-xl lg:text-2xl text-gray-600">Shivaani</p>
            </a>
          </div>
        </div>
        <div className="flex items-center">
          <span role="img" aria-label="phone" className="text-base sm:text-lg md:text-xl lg:text-2xl mr-2">☎️</span>
          <div>
            <a href="tel:+919940253829" className="text-md text-blue-600 hover:text-blue-800 transition duration-300 ease-in-out">
              <p className="font-semibold text-base sm:text-lg md:text-xl lg:text-2xl">+91 9940253829</p>
              <p className="text-base sm:text-lg md:text-xl lg:text-2xl text-gray-600">Jayashree</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;