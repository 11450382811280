import React, { useState,useEffect } from 'react';

const Footer = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const footerContentStyle = isMobile ? {
    ...footerContent, // Base styles
    ...footerContentMobile, // Mobile-specific adjustments, including flexDirection: 'column'
  } : footerContent;
  return (
    <>
      <hr class="h-2 my-8 bg-gray-400 border-0"/>
      <footer style={footerStyle}>
      <div style={footerContentStyle}>
        {!isMobile && <div style={leftSectionStyle}>
          <div>
            <img src="Mugavari_24.png" alt="Mugavari Logo" style={imageStyle} />
          </div>
        </div>}
        <div style={middleSectionStyle}>
          <div style={contactUsStyle}>
            <h2 style={contactHeadingStyle}>CONTACT US</h2>
            <div style={contactDetailsStyle}>
              <div style={contactItemStyle}>
                <span role="img" aria-label="phone" style={phoneIconStyle}>☎</span>
                <a href="tel:+919884720197">
                  <p style={contactTextStyle}>
                      <strong>+91 9884720197</strong><br/> 
                      Celena Rose Andrews
                  </p>
                </a>
              </div>
              <div style={contactItemStyle}>
                <span role="img" aria-label="phone" style={phoneIconStyle}>☎</span>
                <a href="tel:+919360281662">
                  <p style={contactTextStyle}>
                    <strong>+91 9360281662</strong><br/> 
                    Insuvai K
                  </p>
                </a>
              </div>
              <div style={contactItemStyle}>
                <span role="img" aria-label="phone" style={phoneIconStyle}>☎</span>
                <a href="tel:+918940728523">
                  <p style={contactTextStyle}>
                    <strong>+91 8940728523</strong><br/> 
                    Hariharasudhan V
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
        
        <div style={rightSectionStyle}>
          <div style={buttonContainerStyle}>
            <a href=''>
              <button style={buttonStyle}>Home</button>
            </a>
            <a href='./Events'>
              <button style={buttonStyle}>Events</button>
            </a>
            <a href='./Workshops'>
              <button style={buttonStyle}>Workshops</button>
            </a>
            <a href='./Team'>
              <button style={buttonStyle}>Team</button>
            </a>
          </div>
        </div>
      </div>
      <hr style={header}></hr>
      <div style={footerBottom}>
        <div style={copyrightStyle}>
        © COPYRIGHT 2024 <a href="https://guindytimes.com/" target='_blank'>GT</a>
        </div>
        <div style={socialIconsStyle}>
          <a href="https://www.facebook.com/TheGuindy Times/">
            <img src="facebook.png" alt="Facebook" style={iconStyle}></img>
          </a>
          <a href="https://twitter.com/guindytimes">
            <img src="twitter.png" alt="Twitter" style={iconStyle} />
          </a>
          <a href="https://www.instagram.com/theguindytimes/">
            <img src="instagram.png" alt="Instagram" style={iconStyle} />
          </a>
          <a href="https://www.linkedin.com/company/the-guindy-times/mycompany/">
            <img src="linkedin.png" alt="LinkedIn" style={iconStyle} />
          </a>
          <a href="https://www.youtube.com/channel/UCju-3cjTObCWubOgqZ4xH3A/videos">
            <img src="youtube-icon.png" alt="Youtube" style={iconStyle} />
          </a>
          <a href="https://www.quora.com/profile/Guindy-Times">
            <img src="quora.jpg" alt="Quora" style={iconStyle} />
          </a>
        </div>
      </div>
    </footer>
    </>
  );
};

const footerStyle = {
  backgroundColor: '#fff',
  color: '#000',
  display: 'flex',
  flexDirection: 'column',
};

const header = {
    marginLeft: '10px',
    marginRight: '10px',
    fontWeight: 'bold'
}


const footerContent = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px',
};

const footerContentMobile = {
  display: 'flex',
  flexDirection: 'column', // This ensures content stacks vertically on mobile
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 20px',
};

const footerBottom = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 20px', 
  flexShrink: 0, 
};

const leftSectionStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
};

const middleSectionStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: '1', // Takes up remaining space
};

const rightSectionStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
};

const imageStyle = {
  width: '100%',
  maxWidth: '300px',
};

const buttonStyle = {
  padding: '8px 16px',
  backgroundColor: '#fff',
  color: 'black',
  fontWeight: 'bold',
  fontSize: '25px',
  fontFamily: 'Montserrat, sans-serif',
  border: 'none',
  borderRadius: '20px',
  cursor: 'pointer',
  marginBottom: '10px',
};

const buttonContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const copyrightStyle = {
  marginRight: 'auto',
  color: 'black',
  fontSize: '18px',
  fontWeight: 'bold',
};

const socialIconsStyle = {
  display: 'flex',
};

const iconStyle = {
  width: '30px',
  marginLeft: '10px',
  marginRight: '20px',
};

const contactUsStyle = {
  
  padding: '20px',
  borderRadius: '10px',
  width: 'fit-content',
  textAlign: 'center',
};

const contactHeadingStyle = {
  margin: '0',
  color: '#333',
};

const contactDetailsStyle = {
  marginTop: '10px',
};

const contactItemStyle = {
  backgroundColor: 'white',
  padding: '10px',
  color:'black',
  marginBottom: '10px',
  borderRadius: '5px',
  // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  alignItems: 'center',
};

const contactTextStyle = {
  margin: '0',
  marginLeft: '20px',
  fontSize: '17px'
};

const phoneIconStyle = {
  fontSize: '30px',
  color:'black',
  marginLeft: '-10px'
};

export default Footer;
