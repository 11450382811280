import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Contact from "./pages/Contact/Contact";
//import Gallery from "./pages/Gallery/Gallery";
import Home from "./pages/Home/Home";
import Footer from './components/Footer/Footer';
import Navbar from "./pages/Home/Components/Navbar";
import Team from "./pages/Team/Team";
import Events from "./pages/Events/Events";
import Workshops from "./pages/Workshops/Workshops";
//import Magazines from "./pages/Magazines/Magazines";

function App() {
  return (
    <Router>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/contact" element={<Contact/>}></Route> 
        <Route path="/team" element={<Team/>}></Route>
        <Route path="/events" element={<Events/>}></Route>
        <Route path="/workshops" element={<Workshops/>}></Route>
        
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;