import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SponsorSlider.css';
import Marquee from "react-fast-marquee";


import sponsorimage1 from './SponsorsLogo/AACEG LOGO SHARP.jpg';
import sponsorimage2 from './SponsorsLogo/LOGO_CHALLAS_03.png';
import sponsorimage3 from './SponsorsLogo/LOGO-DECATHLON (3).png';
// import sponsorimage4 from './SponsorsLogo/PD logo white.png';
import sponsorimage5 from './SponsorsLogo/poorvika-mobile-logo-3A146EA209-seeklogo.com.png';
import sponsorimage6 from './SponsorsLogo/PSLogo1.png';
import sponsorimage7 from './SponsorsLogo/TIG Logo Variants.png';
  



const SponsorSlider = () => {

  return (
    <>
  
    <h1 className='text-6xl sm:text-7xl md:text-8xl lg:text-9xl font-maintitle text-black m-5 text-center py-20 px-2.5'>Previous Sponsors</h1>
    <Marquee className='py-20' speed={200} autoFill='True'>   
    <img src={sponsorimage1} alt="SponsorImage 1" style={{height:'150px',marginRight:'50px',padding:'20px'}}/>
    <img src={sponsorimage2} alt="SponsorImage 2" style={{height:'150px',marginRight:'50px',padding:'20px'}}/>
    <img src={sponsorimage3} alt="SponsorImage 3" style={{height:'150px',marginRight:'50px',padding:'20px'}}/>
    {/* <img src={sponsorimage4} alt="SponsorImage 4" style={{height:'150px',marginRight:'50px',padding:'20px'}}/> */}
    <img src={sponsorimage5} alt="SponsorImage 5" style={{height:'150px',marginRight:'50px',padding:'20px'}}/>
    <img src={sponsorimage6} alt="SponsorImage 6" style={{height:'150px',marginRight:'50px',padding:'20px'}}/>
    <img src={sponsorimage7} alt="SponsorImage 7" style={{height:'150px',marginRight:'50px',padding:'20px'}}/>
  </Marquee>
    </>
  );
};

export default SponsorSlider;
