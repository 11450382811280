import React from 'react';
// import Mugavari_logo from './Mugavari 23_dark_gold.png';

const Navbar = () => {
  return (
    <nav className="bg-white border-gray-200 dark:bg-gray-900">
    <div className='flex justify-center w-full mb-4 md:mb-0 md:w-auto'>
      <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse mt-5">
        <img src="Mugavari_24.png" className="h-64 object-fill" alt="Mugavari Logo" />
      </a>
    </div>

    <hr class="h-2 my-8 bg-gray-400 border-0"/>

    {/* Navigation Links and Login Button */}
    <div className="flex justify-center items-center w-full">
      <div className="flex flex-wrap justify-center space-x-8">
        <a href="/" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 font-montserrat font-semibold tracking-norma">Home</a>
        <a href="/events" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 font-montserrat font-semibold tracking-norma">Events</a>
        <a href="/workshops" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 font-montserrat font-semibold tracking-norma">Workshops</a>
        <a href="/team" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 font-montserrat font-semibold tracking-norma">Team</a>
        <a href="/contact" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 font-montserrat font-semibold tracking-norma">Contact</a>
      </div>
    </div>

    <hr class="h-2 my-8 bg-gray-400 border-0"/>
  </nav>
  );
};
 
export default Navbar;