import React from 'react';

import './Home.css';
import ShiftingCountdown from './Components/Countdown';
import SponsorCarousel from './Components/SponsorSlider';
import AboutUs from './Components/AboutUs'



const Home = () => {

  return (
    <div>
      <AboutUs />
      <ShiftingCountdown/>
      <SponsorCarousel />
    </div>
  );
};

export default Home;