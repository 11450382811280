import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import "./team.css";

const Teamcard = ({
  image,
  title,
  designation,
  department,
  linkedin,
  instagram,
}) => {
  return (
    <div className="w-[300px] mb-10">
      {/* Added Tailwind CSS classes for border (border, border-gray-300) and shadow (shadow-lg) */}
      <div className="card border border-gray-300 shadow-lg">
        <img src={"https://guindytimes.com/"+image} alt={title} style={{ width: "50%", height:"35%" }} />
        <div>
          <p className="font-montserrat font-bold text-cardname text-nowrap tracking-normal ">{title}</p>
          <p className="font-montserrat font-semibold text-carddept text-nowrap tracking-normal">{designation}</p>
          <p className="font-montserrat text-carddept tracking-normal">{department}</p>
          <div className="social-icons mb-25px">
            {linkedin && (
              <a href={linkedin} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            )}
            {instagram && (
              <a href={instagram} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teamcard;