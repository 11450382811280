import React from "react";

const Eventcard = ({ event }) => {
  return (
    <div className="max-w-lg mx-auto overflow-hidden bg-#0891b2 rounded-lg shadow-lg dark:bg-gray-800 leading-relaxed transition-all duration-300 hover:scale-110">

        <img class="w-full h-64 object-cover" src={event?.image} alt={event?.name}/>

        <div class="p-5 text-center">
        <a className="block text-6xl font-heading font-weight-500 text-gray-800">{event.name}</a>
        <span className="font-heading text-2xl text-gray-700 block mb-4">Registration Fee: {event.fee}</span>
        <p className="font-montserrat text-contentsize  text-gray-600  text-justify tracking-tight mb-4 font-medium">{event.description}</p>
        {/* <button className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent roundeds">Register Now</button> */}
        {/* <button
          className="relative rounded px-5 py-2.5 m-4 overflow-hidden group bg-green-500 hover:bg-gradient-to-r from-green-500 to-green-400 text-white hover:ring-2 hover:ring-offset-2 hover:ring-green-400 transition-all ease-out duration-300"
        >
          <span className="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
          <span className="relative font-medium font-montserrat">Register Now</span>
        </button> */}
        </div>
    
     
</div>

  );
};

export default Eventcard;
