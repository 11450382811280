import React, { useState } from 'react';
import EventsCard from './EventsCard'; // Assuming Eventcard component is in the same directory
import './App.css'

function Events() {

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };
  const eventsData = [
    {
      name: "AU's Got Talent",
      image: "talent.jpg",
      description: "Step into the spotlight and show off your talent at our upcoming talent show! Whether you sing, dance, juggle, or have any other amazing skill, this is your chance to shine. Join us for a day of entertainment and applause as performers like you take the stage to wow the audience. Don't miss out on the opportunity to share your talent with the world!",
      teamsize:"Individual/Team",
      rules:[
        "Can be any sort of skill",
        "Maximum Time limit: 10 mins"
      ],
      
      time_date:"9:30 - 4:30pm, 8th April",
      venue:"Vivek Audi",
    },
    {
      name: "Mock Press",
      image: "press.jpg",
      description: "Get ready to experience the thrill of the spotlight at our Mock Press event! Form a team and dive into the world of celebrity interviews. In this exciting competition, one member of your team will step into the shoes of a famous celebrity, while the rest of the team become journalists eager to uncover the latest scoop. Whether you're channeling the charm of a Hollywood A-lister or the wit of a talk show host, this is your chance to showcase your improvisational skills and quick thinking under pressure. Join us for a fun-filled evening of role-playing and entertainment as you become part of the Mock Press frenzy",
      teamsize:"1-3",
      rules:[
        "One of them will be taking up the role of a famous celebrity",
        "The other members will have to interview that celeb",
        "The unique and innovative interview with best answers wins",
        "Make sure the questions are appropriate and not offensive "
      ],
      
      time_date:"9:30 - 12:30, 8th April",
      venue:"Red Building",
    },
    {
      name: "Cricket Carnival",
      image: "cricket.jpg",
      description: "As the cricket ground basks in the glow of twilight, fans erupt in cheers, witnessing a spectacle of skill and strategy unfold. Amidst the roar of the crowd, players dance between wickets, their determination etched in every stroke. In the commentary box, voices rise and fall, narrating the drama with fervor, encapsulating the essence of the game in a whirlwind of excitement. Step into the pulsating heart of cricket under the enchanting twilight sky! Join us for an evening of pure magic",
      teamsize:"1-2",
      rules:[
        "Live commentary of the match screened should be given"
      ],
      
      time_date:"3:30 - 5:00, 7th April",
      venue:"Tag Audi",
    },
    {
      name: "Front page fiasco",
      image: "front.jpg",
      description: "Decode the news and defend its front-page status in our interactive event! Participants will connect pictures to unravel a story, then argue why it deserves prime placement. It's a test of analytical skills and persuasive arguments. Join us for a thrilling challenge that hones your critical thinking and communication abilities!",
      teamsize:"4-5",
      rules:[
        "Participants will have to connect the Images given to them and decode the news",
        "They have to defend why this news has to be put at the front page of the newspaper",
        "The team/ individual with most persuading reasons, wins"
      ],
      
      time_date:"1:30 to 4:30, 8th April",
      venue:"Red Building",
    },
    {
      name: "Family Feud",
      image: "familyfeud.jpeg",
      description: "Join us for a thrilling game of Family Feud! Form teams with your friends and family, and put your wits to the test as you guess survey responses. With tension, laughter, and friendly competition, it's an unforgettable experience. Get ready to face off and have a blast.",
      teamsize:"5",
      rules:[
        "The host asks a question, and each team tries to guess the most popular answers from a survey",
        "Most relevant answers have more points, and less relevant ones have less",
        "If a team's guess matches a top answer, they get points.",
        "If a team's guess is wrong, they get a strike. Three strikes and they lose their turn.",
        "If one team gets three strikes, the other team can try to steal their points",
        "The team with the most points at the end wins!"
      ],
      
      time_date:"5 pm to 8 pm , 7th April",
      venue:"Tag Audi",
    },
    // {
    //   name: "Spin the Yarn",
    //   image: "spin.jpg",
    //   description: "Join us for an evening of storytelling magic at our Spin the Yarn event! Bring your imagination and creativity as we gather in a cozy setting to share tales that will enchant and inspire. From epic adventures to heartwarming anecdotes, let's weave a tapestry of stories together, bound only by the limits of our imagination. Don't miss out on this chance to unleash your storytelling prowess and connect with fellow enthusiasts in a night of wonder and delight!",
    //   teamsize:"1",
    //   
    //   time_date:"",
    //   venue:"",
    // },
    {
      name: "Campus Heist",
      image: "campus_heist.jpg",
      description: "🎉 Get ready for the ultimate adrenaline-fueled adventure on campus! Presenting Campus Heist - an electrifying college event where your sleuthing skills will be put to the test. 🕵️‍♂️ 📸 How does it work?  Your journey begins with a single photograph, a clue to a hidden gem within our campus. Your mission? To decipher the clue, locate the spot, and snap a pic! But wait, there's more! Each photograph holds the key to the next location, leading you on a thrilling scavenger hunt through every nook and cranny of our vibrant campus.🏆 Assemble your team, strategize your moves, and embark on this epic quest filled with twists, turns, and surprises at every corner. Will you be the first to uncover all the hidden treasures and claim victory?",
      teamsize:"2-4",
      
      time_date:"9:30 to 12:30, 8th April",
      venue:"Mechanical department",
    },
    {
      name: "General Quiz",
      image: "quiz.jpg",
      description: "Gather your team and test your knowledge across a spectrum of subjects at our quiz event! Dive into a diverse array of topics, ranging from sports to history, current affairs to movies, TV shows, and everything in between. Whether you're a pop culture aficionado, a history buff, or just love random trivia, there's something for everyone in this ultimate quiz challenge. So assemble your squad and get ready to showcase your expertise in this thrilling exploration of all things under the sun!",
      teamsize:"1-3",
      
      time_date:"1:30 - 4:30, 8th april",
      venue:"Red Building",
    },{
      name: "கட்டுக்கதை",
      image: "Kattukadhai.jpg",
      description: "நாம் கொண்டாடிய படங்களுக்கான பல்வேறு கதைக்களங்களை வெளிக்கொணர்வதில் இந்த நிகழ்வு கவனம் செலுத்துகிறது ஒவ்வொரு சினிமா ரசிகனுக்குள்ளும் இருக்கும் திரைக்கதை எழுத்தாளனுக்கான போட்டி இது. பங்கேற்பாளர் லாட்டிலிருந்து ஒரு திரைப்படத்தைத் தேர்ந்தெடுக்க வேண்டும், மேலும் அந்த திரைபடத்திலிருந்து மாறுப்பட்ட பாதையை எடுக்கும் ஒரு கதையை எழுத வேண்டும். மிகவும் ஆக்கப்பூர்வமான மற்றும் சுவாரசியமான கதை தேர்ந்தெடுக்கப்படும்",
      teamsize:"3",
      
      time_date:"1:30 to 4:30, 8th April",
      venue:"Red Building",
    },{
      name: "வார்த்தையோடு விளையாடு",
      image: "Varthaiyodu Velyaadu.jpg",
      description: "இந்த நிகழ்வில் பங்கேற்பாளர்களுக்கு ஒரு தலைப்பும் அந்த தலைப்புடன் நெருங்கிய தொடர்புடைய ‘தடைசெய்யப்பட்ட’ வார்த்தையைப் பெறுவார்கள். அவர்களின் சவால்? கொடுக்கப்பட்ட வார்த்தையைப் பயன்படுத்துவதைத் திறமையாகத் தவிர்த்து, 2 நிமிடங்களுக்குப் பேச வேண்டும்.",
      teamsize:"2",
      
      time_date:"9:30 to 12:30, 8th April",
      venue:"Red Building",
    }
  ];



  return (
    <div className="Body">
      <div className="header">
        <h1 className="text-6xl sm:text-7xl md:text-8xl lg:text-9xl font-maintitle text-black ml-5 flex justify-center">EVENTS</h1>
      </div>
      <div className='text-center m-20'>
        <a href="https://forms.gle/koz65S6wQKc3UwPC7" target="_blank" className="relative inline-flex items-center px-12 py-3 overflow-hidden text-4xl font-medium text-indigo-600 border-2 border-indigo-600 rounded-full hover:text-white group hover:bg-gray-50">
          <span className="absolute left-0 block w-full h-0 transition-all bg-indigo-600 opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
          <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
          </span>
          <span className="relative">REGISTER FOR IT!</span>
        </a>
      </div>
      <div className="event-card-container flex justify-center">
          {eventsData.map((event, index) => (
            <EventsCard 
              key={index} 
              event={event} 
              openDialog={openDialog}
              closeDialog={closeDialog}
              isDialogOpen={isDialogOpen}
            />
        ))}
      </div>
    </div>
  );
}

export default Events;
