import React from 'react';
import "./team.css"; // Assuming your original CSS has the necessary styling for the skeleton

const TeamcardSkeleton = () => {
  return (
    <div className="card border border-gray-300 shadow-lg" style={{ width: '300px', height: 'auto', padding: '20px', margin:'5px' }}> {/* Adjust the width and padding as needed */}
      <div className="animate-pulse">
        <div className="bg-gray-300 rounded-full h-64 w-64 mx-auto"></div> {/* Larger image placeholder */}
        <div className="space-y-4 p-6"> {/* Increased spacing & padding for content */}
          <div className="bg-gray-300 h-10 rounded w-3/4 mx-auto"></div> {/* Taller title placeholder */}
          <div className="bg-gray-300 h-10 rounded w-5/6 mx-auto"></div> {/* Taller designation placeholder */}
          <div className="bg-gray-300 h-10 rounded w-2/3 mx-auto"></div> {/* Taller department placeholder */}
          <div className="flex justify-center space-x-3 mt-6"> {/* Larger spacing for social icons */}
            <div className="bg-gray-300 h-14 w-14 rounded-full"></div> {/* Larger Instagram icon placeholder */}
            <div className="bg-gray-300 h-14 w-14 rounded-full"></div> {/* Larger LinkedIn icon placeholder */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamcardSkeleton;