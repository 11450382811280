import React from 'react';
import './App.css';
import Eventcard from './EventsCard';

const Workshops = () => {
    const eventsData = [
        {
          "name": "Architecture photography workshop",
          "image": "https://www.shutterstock.com/image-vector/workshops-vector-typography-banner-on-260nw-1432672841.jpg",
          "description": "First one hour will be an introduction to basics of photography, involving theoretical concepts and live demo. There'll be a hands on session and a photowalk for the next one hour, where attendees will be taken around the campus with a guide to capture various architectures.",
          "fee": "free"
        }
      ];
    return (
        <div className="Body">
                <div className='header'>
                    <h1 className='text-6xl sm:text-7xl md:text-8xl lg:text-9xl font-maintitle text-black m-5'>WORKSHOPS</h1>
                </div>
            <div className="event-card-container flex justify-center">
                {eventsData.map((event, index) => (
                    <Eventcard key={index} event={event} />
                ))}
            </div>

            {/* <div className="flex justify-center items-center min-h-screen bg-white">
                <div className='text-center p-4'>
                    <h1 className='text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-[#003366]'>
                        COMING SOON
                    </h1>
                    <p className='text-xl mt-4 text-[#110092]'>
                        Stay tuned for amazing workshops.
                    </p>
                </div>
            </div>   */}

      </div>
    );
};

export default Workshops;