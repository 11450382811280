import React,{useState, useEffect} from "react";
import Teamcard from "./Teamcard";
import "./team.css";
import TeamcardSkeleton from "./Teamcardskeleton";
import axios from 'axios';

const Team = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const ebOrder = ['President', 'Vice President I','Vice President II', 'Executive Editor (Tamil)', 'Executive Editor (English) I',
                 'Executive Editor (English) II','Director of contents (Tamil)','Director of Contents (English) I','Director of Contents (English) II','Director of Contents (English) III','Head of Interviews I','Head of Interviews II',
                 'Director of Photography I','Director of Photography II','Director of Photography III','Director of Social Media I','Director of Social Media II','Director of Design I','Director of Design II','Director of Design III','Director of Events I','Director of Events II',
                  'Director of Marketing and ER I','Director of Marketing and ER II','Director of Video Editing','Director of Tech I','Director of Tech II']
  useEffect(()=>{
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://guindytimes.com/api/eb/2023');
        const sortedEbData = response.data.data.eb_data.sort((a, b) => {
          const indexA = ebOrder.indexOf(a.designation); 
          const indexB = ebOrder.indexOf(b.designation); 
          return indexA - indexB; 
        });
        setTeamMembers(sortedEbData);
        setLoading(false);
      } catch (error) {
        console.error('There was an error!', error);
        setLoading(false);
      }
    };
    fetchData();
  },[]);

  return (
    <section className="section-white">
      <div>
        <p className="text-6xl sm:text-7xl md:text-8xl lg:text-9xl font-maintitle text-black">Meet The Team</p>
        <br />
        <div className="flex flex-wrap justify-around">
        {loading ? (
            Array.from({ length: 20 }, (_, index) => (
                <TeamcardSkeleton key={index} />
              ))
          ) : (
            teamMembers.length > 0 && teamMembers.map((member) => (
              <Teamcard key={member.id} {...member} />
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default Team;