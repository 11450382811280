// AboutUsBox.js

import React from 'react';
import './AboutUs.css';
import gtlogo from './GT Logo Red Iso shadow.png';
import mugavarilogo from './Mugavari_24.png';

const AboutUsBox = () => {
  return (
    <div className="shadow-xl m-12 rounded py-15">
      <h1 className='font-maintitle text-mainheader text-center'>About Us</h1>
      <p className='sm:text-4xl md:text-7xl lg:text-8xl font-maintitle text-mainheader text-center'>Guindy Times</p>
      <div className="content">
        <div className="image-container">
          <img className='gtlogo' src={gtlogo} alt="About Us" />
        </div>
        <div className="text-container">
          <p className='font-montserrat text-csize text-justify font-medium tracking-normal '>
          The Guindy Times is the official campus magazine of CEG, AC Tech and SAP. This students' magazine is a media body which strives to provide updates about the happenings both within and outside the campus walls. We are omnipresent in college as well as in social media. Facebook, Instagram, Quora, Twitter, Snapchat, Linkedin, Youtube. You name it and we are there. The Guindy Times is one place where you can find everything at one place.
          </p>
        </div>

        
      </div>
      <p className='sm:text-4xl md:text-7xl lg:text-8xl font-maintitle text-mainheader text-center'>Mugavari</p>
      <div className='content'>
      <div className="image-container">
          <img className='mugavarilogo' src={mugavarilogo} alt="About Us" />
        </div>
        <div className="text-container">
          <p className='font-montserrat text-csize text-justify font-medium tracking-normal '>
          Mugavari is the Flagship Event of The Guindy Times. Presenting the 5th edition of Mugavari to the creative minds and souls. Mugavari is an inter-college media fest which is all set to happen on April 7 and 8 at Anna University. The Theme for Mugavari'24 is “Create Innovate Express”. More than 10+ events and 2+ workshops are to be conducted on these two days. Mugavari Awards is a signature event that embarks on appreciating art and artists of our society which is completely chosen by students of Anna University. Finding address to the unaddressed and Travelling together towards destiny of art and artists.
          </p>
        </div>
      </div>
    </div>
    
  );
};

export default AboutUsBox;
